<template>
  <div class="urlDetails pa-8">
    <r-error-message
      v-if="fetchUrlDetailsError"
      :errors="[fetchUrlDetailsError]"
    />
    <div v-if="!fetchingUrlDetails && !fetchUrlDetailsError">
      <r-page-header
        class="urlDetails--header"
        previousroute="/admin/urls"
        :label="urlDetails.url"
      />
      <v-container fluid>
        <v-row>
          <v-col sm="6">
            <p class="urldetails--property">
              Name:
            </p>
            <p class="urldetails--value">
              {{ urlDetails.name }}
            </p>
            <p class="urldetails--property">
              Number of Reviews:
            </p>
            <p class="urldetails--value">
              {{ urlDetails.numReviews }}
            </p>
            <p class="urldetails--property">
              Last Reviewed:
            </p>
            <p class="urldetails--value">
              {{ formatDate(urlDetails.lastReviewed) }}
            </p>
            <p class="urldetails--property">
              Average Rating:
            </p>
            <p class="urldetails--value">
              {{ urlDetails.averageRating }}
            </p>
            <p class="urldetails--property">
              Scrape Status:
            </p>
            <p class="urldetails--value">
              {{ formatStatus(urlDetails.scrapeJob.status) }}
            </p>
            <p class="urldetails--property">
              Scrape Failure Count:
            </p>
            <p class="urldetails--value">
              {{ urlDetails.scrapeJob.failureCount }}
            </p>
          </v-col>
          <v-col sm="6">
            <p class="urldetails--property">
              Scrape Retry Count:
            </p>
            <p class="urldetails--value">
              {{ urlDetails.scrapeJob.retryCount }}
            </p>
            <p class="urldetails--property">
              Number of Reviews Last Scraped:
            </p>
            <p class="urldetails--value">
              {{ urlDetails.scrapeJob.numReviewsLastScrape }}
            </p>
            <p class="urldetails--property">
              Date Last Found Reviews:
            </p>
            <p class="urldetails--value">
              {{ formatDate(urlDetails.scrapeJob.dateLastFoundReviews) }}
            </p>

            <p class="urldetails--property">
              Scrape Time:
            </p>
            <p class="urldetails--value">
              {{ formatUpdateSlot(urlDetails.scrapeJob.updateSlot) }}
            </p>
            <p class="urldetails--property">
              Non Canonical URLs:
            </p>
            <p
              v-for="(url, index) in urlDetails.nonCanonicalUrls"
              :key="index"
              class="urldetails--value d-block"
            >
              {{ url }}
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0">
            <p class="urldetails--property">
              Clients:
            </p>
            <v-data-table
              :headers="headers"
              :items="urlDetails.clients"
              class="elevation-1"
              hide-default-footer
            />
          </v-col>
        </v-row>
        <v-row
          v-if="showReScrapeButton"
          class="mt-3"
        >
          <v-col>
            <r-button
              label="Re-scrape"
              :disabled="isReScrapeDisabled"
              @click="retriggerScrapping"
            />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import RPageHeader from '@/components/library/molecules/RPageHeader.vue'
import RButton from '@/components/library/atoms/RButton'
import RErrorMessage from '@/components/library/atoms/RErrorMessage'
import formatDate from '@/utils/formatDate'
import dateStrToDate from '@/utils/dateStrToDate'
import { SCRAPE_JOB_STATUSES } from '@/utils/constants'

const NOT_DETERMINED = 'NA'
const VALIDATION_PREFIX = 'Validation: '

export default {
  name: 'UrlDetails',
  components: {
    RPageHeader,
    RButton,
    RErrorMessage,
  },
  data() {
    return {
      urlId: null,
      reScrapeCount: 0,
      pollScrapeStatusFn: () => {},
      headers: [
        {
          text: 'Client ID',
          align: 'start',
          sortable: false,
          value: 'clientId',
        },
        {
          text: 'Name',
          sortable: false,
          value: 'name',
        },
        {
          text: 'Scrape Cadence',
          sortable: false,
          value: 'scrapeCadence',
        },
      ],
      SCRAPE_JOB_STATUSES,
      pollingTimer: null,
      stopPollingTimer: null,
    }
  },
  computed: {
    ...mapState('admin', [
      'urlDetails',
      'fetchingUrlDetails',
      'fetchUrlDetailsError',
    ]),
    showReScrapeButton() {
      return !!this.urlDetails.scrapeJob._id
    },
    isReScrapeDisabled() {
      return !!this.reScrapeCount
    },
  },
  watch: {
    fetchUrlDetailsError(nextError) {
      if (nextError) {
        this.removeStatusPoll()
      }
    },
  },
  async beforeMount() {
    const { urlId } = this.$route.params
    this.urlId = urlId
    await this.getUrlDetails({ urlId })
  },
  mounted() {
    this.registerSatusPoll()
    this.$data.pollScrapeStatusFn()
  },
  beforeDestroy() {
    this.removeStatusPoll()
  },
  methods: {
    ...mapActions('admin', [
      'getUrlDetails',
      'rescrapeUrl',
      'getScrapeStatus',
    ]),
    async fetchScrapeStatus() {
      const { urlId } = this
      await this.getScrapeStatus({ urlId })

      const { status } = this.urlDetails.scrapeJob

      if (status === 'complete' || status === 'failed') {
        this.reScrapeCount = 0
        clearInterval(this.pollingTimer)
      }
    },
    retriggerScrapping() {
      const { urlId } = this

      this.reScrapeCount += 1
      this.rescrapeUrl({ urlId })
      this.$data.pollScrapeStatusFn()
    },
    formatDate(date) {
      if (!date) {
        return NOT_DETERMINED
      }

      return formatDate({
        $moment: this.$moment,
        date: dateStrToDate(date),
      })
    },
    formatUpdateSlot(timeInMinutes) {
      if (!timeInMinutes) {
        return NOT_DETERMINED
      }

      let hours = Math.floor(timeInMinutes / 60)
      let minutes = timeInMinutes - hours * 60
      hours %= 24
      hours = hours < 10 ? `0${hours}` : hours
      minutes = minutes < 10 ? `0${minutes}` : minutes

      return `${hours}:${minutes}`
    },
    formatStatus(status) {
      if (status.includes(VALIDATION_PREFIX)) {
        const cleanedStatus = status.replaceAll(VALIDATION_PREFIX, '')
        return `${VALIDATION_PREFIX}${SCRAPE_JOB_STATUSES[cleanedStatus]}`
      }

      return SCRAPE_JOB_STATUSES[status]
    },
    registerSatusPoll() {
      this.$data.pollScrapeStatusFn = () => {
        this.pollingTimer = setInterval(() => this.fetchScrapeStatus(), 5000)
        this.stopPollingTimer = setTimeout(() => clearInterval(this.pollingTimer), 60000)
      }
    },
    removeStatusPoll() {
      clearInterval(this.pollingTimer)
      clearTimeout(this.stopPollingTimer)
    },
  },
}
</script>

<style scoped>
.urldetails--property,
/deep/.v-data-table > .v-data-table__wrapper > table > thead > tr > th
 {
  font-size: 14px !important;
  line-height: 1.5;
  font-weight: 500;
  margin: 15px 0px;
}
.urldetails--value,
/deep/.v-data-table > .v-data-table__wrapper > table > tbody > tr > td
{
  font-size: 13px !important;
  line-height: 1.5;
  margin-bottom: 0px;
}
</style>
